.student-page {
    .parasha-subheader {
        font-family: 'MaccabiBlock';
        .haftara {
            > span {
                font-size: 12px;
                color: #c2925c;
            }

            > span:nth-child(2) {
                font-size: 14px;
                font-weight: bold;
            }
        }   
        .parasha {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;

            > span {
                font-size: 12px;
                color: #54240f;
            }
            
            .select-field {
                width: 100%;
                display: inline-flex;
            }

            select {
                border: 0;
                font-size: 14px;
                color: #54240f;
                font-weight: bold;
            }
        }   
    }

    .student-header {
        font-family: 'FbDakar';
        color: #ecb04e;
        font-size: 45px;
        background-image: url("../images/student-page-header.png");
        height: 62px;
        // width: 386px;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        position: relative;
        .back-button {
            position: absolute;
            right: 10px;
            top: -5px;
            cursor: pointer;
            img {
                height: 35px;
            }
        }
    }


    .student-sub-header {
        position: relative;
        .student-sub-header-darkbrown {
            background-image: url("../images/student-subheader-dark-brown.png");
            z-index: 5;
            background-repeat: no-repeat;
            position: absolute;
            left: -28px;
            width: 408px;
            top: -71px;
            height: 180px;
        }
        
        .student-sub-header-brown {
            background-image: url("../images/student-subheader-brown.png");
            z-index: 10;
            background-repeat: no-repeat;
            position: absolute;
            left: 28px;
            width: 378px;
            top: 52px;
            height: 87.5px;
        }
    
        .student-sub-header-yellow {
            z-index: 20;
            background-image: url("../images/student-subheader-yellow.png");
            background-repeat: no-repeat;
            position: absolute;
            left: 10px;
            width: 356px;
            top: 14px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            height: 65px;

            .haftara a {
                color: #c2925c;
                font-size: 14px;
                text-decoration: underline;
            }
        }
    }

    .iframe-wrapper {
        height: 623px;
        padding-top: 73px;
        max-width: 380px;
        width: calc(100vw + 18px);
        overflow: hidden;
        iframe {
            height: 100%;
            width: 100%;
            max-width: 100vw;
        }
    }
}