.login-page {
    background-image: url('../images/login-background.png');
    height: 100%;
    background-position: center;
    margin-right: -13px;

    .ant-alert-error {
        padding: 3px;
        margin-bottom: 5px;
        border-radius: 20px;   
    }

    .login-form {
        position: absolute;
        bottom: 12px;
        right: 62px;
        color: white;
        
        .login-welcome {
            font-size: 25px;
        }

        > span {
            font-size: 24px;
        }

        input {
            width: 247px;
            height: 46.7px;
            margin: 7.3px 0 16px;
            padding: 8.7px 17px 8px 16px;
            border-radius: 94px;
            background-color: #ffffff;
        }

        .login-button {
            cursor: pointer;
            background-image: url('../images/green-button-bg.png');
            border: 0;
            width: 106px;
            height: 43px;
            font-size: 14px;
            background-color: transparent;
        }
    }
}