.logo-manager-container {
    width: 79.8px;
    height: 46.4px;
    margin: 2.6px 9.5px 0 0;
    object-fit: contain;
    position: absolute;
    top: 20px;
    left: 10px;
}
  

.students-list {
    height: 460px;
    overflow-x: hidden;
}

.ant-tabs-tab-btn {
    color: #e1bb7b;   
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #54240f;     
    font-weight: bold;    
}

.ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 0;
}

.actions-area {
    .action-button {
        cursor: pointer;
        min-height: 55px;
        width: 55px;
        border-radius: 50%;
        margin: 5px;
        background-color: #79c945;
        color: #fff;
        font-size: 30px;
        padding-bottom: 6px;
        border-width: 0;
        .download-button {
            width: 30px;
            object-fit: contain;
        }
        .sign {
            width: 17.9px;
            height: 17.9px;
            object-fit: contain;
        }
    }
}
