.page-wrapper {
    height:  100vh;
    max-height: 667px;
    overflow: scroll;
    position: relative;

    .back-button {
        position: absolute;
        right: 20px;
        cursor: pointer;
        z-index: 1000;
        top: 30px;
    }
    
    .app-header {
        min-height: 90px;
        padding: 2.7px 8.5px 13.2px 9.7px;
        border-radius: 0 0 19px 19px;
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        z-index: 500;
        position: relative;
    
        
        .tabs-wrapper {
            margin-bottom: -29px;
            
            .ant-tabs-nav-list {
                display: flex;
                align-items: stretch;
                width: 100%;
            }
            .ant-tabs-tab {
                display: flex;
                justify-content: center;
                text-align: center;
                margin: 3px 8px;
                flex: 1;
            }
            .ant-tabs-ink-bar {
                background: #54240f;
                height: 4px;
            }
            .ant-tabs-ink-bar {
                background: #54240f;
                &.ant-tabs-ink-bar-animated {
                    height: 4px;
                }
            }
        }
    }
}