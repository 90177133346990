.student-main-page {
    .student-main-header {
        height: 130px;
        z-index: 20;
        position: relative;

        .user-ranks-wrapper {
            position: absolute;
            top: 74px;
            left: 74px;
        }

        .jingle-button {
            position: absolute;
            left: 4px;
            top: 66px;
            img {
                width: 35px;
            }
        }
        
        .avatar-header {
            height: 149px;
            position: absolute;
            right: 0;
            top: 5px;
            width: 86px;
            background-repeat: no-repeat;
            background-image: url('../images/avatar-header-wrapper.png');

            
        }

        .today-date {
            position: absolute;
            bottom: 5px;
            color: white;
            font-size: 11px;
            right: 30%;
            > span:first-child { 
                color: #cfa78c;
            }
        }

        .default-header-avatar {
            // background-image: url('../images/default-avatar-header.png');
            img {
                width: 60px;
                margin-right: 2px;
                margin-top: -6px;
            }
            position: absolute;
            height: 71px;
            width: 57px;
            top: 51px;
            right: 0px;
            cursor: pointer;
            background-size: 49px;
            background-repeat: no-repeat;
            > span {
                top: 60px;
                right: 0px;
                position: absolute;
                width: 57px;
                color: #481001;
                font-weight: bold;
                font-size: 13px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .student-main-header-bg {
        height: 141px;
        z-index: 10;
        margin-top: -32px;
        margin-left: -7px;
        background-image: url('../images/student-main-page-header.png');

        .student-main-logo-wrapper {
            position: absolute;
            left: 157px;
            bottom: 56px;
            transform: rotate(10deg);
            img {
                
                width: 61px;
            }
        }
    }

    .student-main-navbar {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: 93px;

        img {
            margin-bottom: -8px;
        }

        span {
            margin-top: 5px;
            font-size: 14px;
        }

        .nav-item {
            min-width: 50px;
            margin-top: 8px;
            cursor: pointer;
            font-weight: bold;
        }

        .selected {
            background-repeat: no-repeat;
            background-image: url('../images/navbar-item-selected-bg.png');
        }
    }

    .student-main-page-footer-bg {
        background-image: url('../images/student-main-page-footer.png');
        background-size: 375px;
        width: 100vw;
        max-width: 375px;
        position: absolute;
        bottom: 0;
        background-repeat: no-repeat;
    }
}

.avatar-header-stars-wrapper {
    background-image: url("../images/stars-wrapper.png");
    background-repeat: no-repeat;
    position: relative;
    top: 104px;
    right: 10px;
    height: 50px;
    .avatar-stars {
        position: absolute;
        top: -8px;
        left: 8px;
        height: 25px;
        width: 25px;
        background-repeat: no-repeat;
        background-image: url("../svgs/star.svg");
    }
    > span {
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        color: #facb40;
        left: -22px;
        position: relative;
        top: 5px;
    }
}

.user-ranks-wrapper {
    margin-left: -20px;
    margin-top: -10px;
    img {
        width: 240px;
    }
}