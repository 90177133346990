.mission-container {
    .mission-bg {
        position: absolute;
        top: 0;
        left: 0;
    }

    .page-subtitle {
        position: absolute;
        top: 36px;
        width: 100%;
        left: 9%;
        height: 125px;
        background-repeat: no-repeat;
        background-image: url('../images/subtitle-bg.png');
        > span {
            position: relative;
            top: 68px;
            left: -32px;
            color: #956b3b;
            margin: auto;
            display: block;
            width: 78%;
        }
        .subtitle-back-button {
            top: 93px;
            position: absolute;
            right: 45px;
            cursor: pointer;
            img {
                height: 45px;
            }
        }

    }
}

.mission-footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 340px;
}

.mission-modal-body {
    padding: 5px;
    text-align: right;
    font-size: 14px;
    color: #A56E30;
    display: flex;
    flex-direction: column;
    height: 100%;
    .select-answer-wrapper {
        text-align: left;
        .ant-form-item {
            margin-bottom: 12px;
        }
    }
    .mission-modal-footer {
        margin-top: auto;
    }
}


.congrats {
    color: #A56E30;
    font-family: 'FbDakar';
    font-size: 30px;
    text-align: center;
    .complete-subtitle {
        font-size: 40px;
    }
    .avatar-header-stars-wrapper {
        margin: auto;
        width: 41px;
        top: initial;
        right: initial;
        > span {
            left: initial;
            top: -4px;    
            font-size: 13px;
            font-weight: initial;
            font-family: 'MaccabiBlock';
        }
    }
    margin-bottom: 10px;
}

.bonus-text {
    // height: 150px;
    margin-bottom: 20px;
    text-align: right;
}

.support-check {
    position: relative;
    color: rgb(18, 179, 18);
    font-size: 30px;
    span {
        position: absolute;
        top: -13px;
        left: 22px;
        width: 100%;
        text-align: center;
        font-family: 'MaccabiBlock';
    }
}