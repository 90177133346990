.green-btn {
    background-color: #79c945;
    height: 41px;
    color: #ffffff;
    font-size: 17px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: #87dc4f;
        color: #ffffff;
    }
}

.red-btn {
    background-color: rgb(185, 14, 14);
    height: 41px;
    color: #ffffff;
    font-size: 17px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: rgb(190, 20, 20);
        color: #ffffff;
    }
}

.btn-small {
    height: 20px;
    font-size: 12px;
}

.btn-square {
    border-radius: 0;
}
.btn-full-width {
    width: 100%;
}

.form-label {
    display: block;
    font-size: 11px;
    text-align: right;
    color: #a8a591;
}