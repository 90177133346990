

.footer-with-button {
    height: 80px;
    border-radius: 19px 19px 0 0;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    z-index: 500;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    button {
        left: 30%;
        background-color: #79c945;
        width: 123.8px;
        height: 41px;
        color: #ffffff;
        font-size: 17px;
        border: none;
        border-radius: 20px;
        cursor: pointer;
    }
}