
.row-box-item-list-user {


    .avatar-wrapper {
        position: relative;

        .green-dot {
            background: #09b909;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            position: absolute;
            right: 7px;
        }
        .red-dot {
            background: red;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            position: absolute;
            right: 7px;
        }
    }
    
    .name {
        width: 100px;
        font-family: 'MaccabiBlock';
        font-size: 14px;
        line-height: 1.36;
        letter-spacing: 0.18px;
        color: #ababab;
    }
    
    .students-number {
        font-family: 'MaccabiBlock';
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        color: #54240f;
        display: block;
        width: 30px;
    }
    
    .talmidim {
        width: 89px;
        display: block;
        font-size: 14px;
        color: #a8a591;
    }

    .user-item-buttons {
        display: flex;
        margin-right: auto;
    }
}
