.user-profile {
    position: relative;
    .user-stars {
        position: relative;
        .star-icon {
            background-repeat: no-repeat;
            background-image: url("../svgs/star.svg");
            width: 25px;
            height: 25px;
            left: 125px;
            top: -4px;
            position: absolute;
        }
        
        .user-count {
            width: 44px;
            background: #54240F;
            color: white;
            display: block;
            margin: auto;
            font-size: 13px;
            border-radius: 13px;
            margin-top: -30px;
        }
    }
    .user-profile-container {
        position: relative;
        border: 25px solid #925029;
        height: 490px;
        padding-top: 71px;

        hr {
            background: yellow;
        }

        .user-fullname {
            font-weight: bold;
            font-size: 14px;
            color: #481001;
        }

        .user-ranks-wrapper {
            img {
                width: 285px;
            }
        }

        .user-parashot {
            display: flex;
            background: #e1bb7b57;
            margin: 15px;
            padding: 5px;
            border-radius: 5px;
            color: #481001;
            justify-content: flex-start;
            align-items: center;
            text-align: right;

            > div {
                font-size: 13px;
                flex-grow: 1;
                flex-basis: 0;
                text-align: center;
                height: 100%;
            }

            .image-parashot {
                background-image: url('../images/profile-parashot.png');
                width: 75px;
                height: 95px;
                background-repeat: no-repeat;
            }
        }
    }

    .student-sub-header-darkbrown-slices {
        position: absolute;
        background-image: url("../images/student-subheader-dark-brown-slices.png");
        z-index: 0;
        background-repeat: no-repeat;
        position: absolute;
        left: 18px;
        width: 340px;
        top: 20px;
        height: 74px;
    }

    .user-profile-background-yellow {
        background: #fff2b2;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 65px;
        border-radius: 10px;
        border: 1px solid #f7d539;
    }
}

.logout-button {
    font-size: 12px;
    text-align: left;
    padding: 0 17px;
    font-family: MaccabiBlock-Light;
    margin-top: -5px;
    a {
        margin-bottom: -1px;
        color: #481001;
        margin-right: 5px;
        margin-bottom: 5px;
    }
}

.user-profile-avatar {
    position: absolute;
    top: 16px;
    z-index: 10;
    left: 76px;
    cursor: pointer;
    img {
        width: 70px;
    }
    svg {
        position: absolute;
        left: 68px;
        top: 17px;
        z-index: 100;
        color: #efefef;
        border: 1px solid #ffffff;
        border-radius: 50%;
        background: #54240f;
        padding: 2px;
    }
}

.choose-avatar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    .avatar > img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin: 10px;
        cursor: pointer;
        border: 5px solid #54240f;
    }
}