.map-container {
    background-image: url('../images/map-background.png');
    background-position-y: center;
    height: 490px;
    overflow: scroll;
    
    .mission-items {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .mission-item {
            cursor: pointer;

            img {
                max-width: 310px;
            }

            .mission-item-text {
                margin-top: -55px;
                span {
                    text-shadow: 0 3px 4px rgba(0, 0, 0, 0.31);
                    font-family: FbDakar;
                    font-size: 32px;
                    color: #b8fef0;
                }
  
            }

            .enter-mission-icon {
                height: 100px;
                width: 100px;
                margin: auto;
                margin-bottom: -50px;
                background-size: contain;
                background-repeat: no-repeat;
                position: relative;
                background-image: url('../images/enter-mission.png')
            }

            .locked-mission-icon {
                height: 50px;
                width: 100px;
                margin: auto;
                margin-bottom: -60px;
                margin-right: -30px;
                background-size: contain;
                background-repeat: no-repeat;
                position: relative;
                background-image: url('../images/locked-mission.png')
            }
            
            .visited-mission-icon {
                height: 90px;
                width: 100px;
                margin: auto;
                margin-bottom: -60px;
                margin-right: -30px;
                background-size: contain;
                background-repeat: no-repeat;
                position: relative;
                background-image: url('../images/visited-mission.png')
            }
        }
    }
}