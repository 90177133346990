.rank-image-wrapper {
    background: #e1bb7b57;
    border-radius: 5px;
    padding: 10px;
}

.new-rank-congrats {
    color: #A56E30;
    font-family: 'FbDakar';
    font-size: 30px;
    font-weight: 100;
}

.sub-text-wrapper {
    line-height: 20px;
    margin-bottom: 50px;
}