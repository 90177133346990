@font-face {
  font-family: 'MaccabiBlock-Bold';
  src: url("./fonts/MaccabiBlock_V2-Bold.otf") format("opentype");
}

@font-face {
  font-family: 'MaccabiBlock-Light';
  src: url("./fonts/MaccabiBlock_V2-Light.otf") format("opentype");
}
@font-face {
  font-family: 'MaccabiBlock';
  src: url("./fonts/MaccabiBlock_V2-Regular.otf") format("opentype");
}
@font-face {
  font-family: 'FbDakar';
  src: url("./fonts/FbDakar-Regular.otf") format("opentype");
}

body {
  margin: 0;
  height: 100%;
  font-family: 'MaccabiBlock', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-items: center;
  justify-content: center;
}

.font-dakar {
  font-family: 'FbDakar';
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent; /* make scrollbar transparent */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.avatar-small {
  height: 56px;
  width: 56px;
  background-image: url("./svgs/default-avatar.svg");
  background-repeat: no-repeat;
  background-size: 55px;
}
