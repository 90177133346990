.form-error {
    font-size: 14px;
    color: red;
    border: 1px solid red;
    padding: 0 20px;
    background: #ffeeee;
    border-radius: 5px;
}

.icon-button {
    img:hover {
        background: blue;
    }
}