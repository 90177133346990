.add-guide-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 475px;
    overflow: scroll;

    .guide-header {
        height: 57px;
        padding: 2.7px 8.5px 13.2px 9.7px;
        border-radius: 0 0 19px 19px;
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        z-index: 500;
        position: relative;
    }
    
    .add-guide-header {
        width: 178px;
        height: 24px;
        margin: 14.4px 10px 10.6px 9.5px;
        font-family: FbDakar;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.65px;
        text-align: center;
        color: #e1bb7b;
        position: relative;
        top: 30%;
    }
    
    .add-guide-form {
        height: 100%;
        width: 90%;
    }
    
    .profile-pic {
        font-family: sans-serif;
        height: 190px;
        
        button {
            background-color: #79c945;
            color: #ffffff;
            font-size: 17px;
            border: none;
            padding: 3%;
            border-radius: 20px;
        }
    }
    
    .logo-add-guide-container {
        width: 179.8px;
        height: 46.4px;
        margin: 2.6px 9.5px 0 0;
        object-fit: contain;
        position: absolute;
        top: 35px;
        left: 10px;
    }
    
    .input-field {
        padding: 0;
        border: none;
        border-bottom: 2px solid #dfd8d0;
        background-color: #f6f4e8;
        font-size: 14px;
        margin-bottom: 30px;
        position: relative;
    }
}

