
.add-student-container {
    height: 490px;
    overflow: scroll;
    display: flex;
    align-items: center;
    justify-content: center;

    .stars-wrapper {
        .input-field {
            margin-bottom: 10px;
        }

        > div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
        }
    }

    .textarea-field {
        textarea {
            background: #f6f4e8;
        }
    }

    .nusah-lbl {
        color: #a8a591;
        text-align: right;
        font-size: 14px;
    }
    
    .guide-header {
        height: 57px;
        padding: 2.7px 8.5px 13.2px 9.7px;
        border-radius: 0 0 19px 19px;
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        z-index: 500;
        position: relative;
    }
    
    .add-guide-header {
        width: 178px;
        height: 24px;
        margin: 14.4px 1px 10.6px 9.5px;
        font-family: FbDakar;
        font-size: 24px;
        line-height: 1.33;
        letter-spacing: -0.65px;
        text-align: center;
        color: #e1bb7b;
        position: relative;
        top: 30%;
    }
    
    .add-guide-form {
        height: 100%;
        width: 90%;
    }
    
    .profile-pic {
        font-family: sans-serif;
        height: 190px;
        
        button {
            background-color: #79c945;
            color: #ffffff;
            font-size: 17px;
            border: none;
            padding: 3%;
            border-radius: 20px;
        }
    }
    
    .logo-shevet-13 {
        width: 80.8px;
        height: 46.5px;
        margin: 2.6px 9.5px 0 0;
        object-fit: contain;
        position: absolute;
        top: 40px;
        left: 10px;
    }
    
    .input-field {
        padding: 0;
        border: none;
        border-bottom: 2px solid #dfd8d0;
        background-color: #f6f4e8;
        font-size: 14px;
        margin-bottom: 30px;
        position: relative;
    }
    
    .select-buttons-wrapper {
        .select-buttons {
            margin-top: 10px;
            display: flex;
            display: flex;
            align-items: flex-start;
            .select-button {
                outline: none;
                border: 0;
                &:nth-child(2) {
                    margin-right: 5px;
                }
                &:nth-child(3) {
                    margin-right: 5px;
                }
                cursor: pointer;
                font-size: 14px;
                border-radius: 59px;
                background-color: transparent;
                padding: 0px 14.1px 2.6px 13.4px;
                color: #79c945;
                border: 1px solid #79c945;
                &.selected {
                    border: 0;
                    background-color: #79c945;
                    color: #fff;
                }
            }
        }
    }

    .birth-date {
        // width: 195px;
        text-align: right;

        .date-picker { 
            background: #f6f4e8;
            border: 0;
            border-bottom: 2px solid #dfd8d0;
            outline: none;
            width: 100%;
        }

        .hebrew-date {
            color: #a8a591;
            font-size: 13px;
        }

        .ant-checkbox-wrapper {
            color: #a8a591;
        }
    }
}
