.user-ranks {
    position: relative;
}

.rank-display-names {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    width: 270px;
    margin-right: 5px;
    margin-top: 5px;
    color: #54240F;
    font-size: 12px;
    font-weight: 100;
    font-family: MaccabiBlock-Light;
    flex-direction: row-reverse;
    margin-bottom: 10px;
}

.current-step {
    margin: 10px 0;
}