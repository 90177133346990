.select-field {
    width: 100%; 
    .select-lbl {
        display: block;
        font-size: 11px;
        text-align: right;
        color: #a8a591;
    }
    select {
        border: 0;
        background: transparent;
        border-bottom: 2px solid #dfd8d0;
        font-size: 14px;
        color: #a8a591;
    }
}