.manage-student-container {
    display: block;

    .mission-actions-wrapper {
        display: flex;
        button {
            margin-right: 10px;
        }
    }

    .row-box-item-list-student-manager {
        font-size: 14px;
        text-align: right;
        color: #ababab;

        .row-box-item-list {
            > div {
                padding-left: 10px;
            }
            padding-right: 20px;

            > div:last-child {
                margin-right: auto;
                button {
                    height: initial;
                }
            }
        }
    }
}