.ham-btn {
  font-size: 22px;
  color: #e1bb7b;
  border: 0;
  top: 40px;
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 1000;
  :hover {
    color: #e1bb7b;
  }
  :active {
    color: #e1bb7b;
  }
}

.menu-items {
  text-align: right;
}