.edit-task {
    border: 1px solid #dfddcc;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    font-size: 15px;
    text-align: right;
    background: #ffffff;
    .edit-task-title {
        display: flex;
        > div:last-child {
            margin-right: auto;
            svg {
                font-size: 1.2em;
                border: 1px solid #91784c;
                padding: 2px;
                border-radius: 5px;
                cursor: pointer;
                color: #91784c;
            }
        }
    }
}