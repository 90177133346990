

html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  max-height: 667px;
  background: #f1f5f8;
  min-height: 70vh;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Architects Daughter", cursive;
  text-align: center;
  font-size: 130%;
  direction: rtl;
}

.open-admin-menu {
  width: 1px;
  height: 1px;
  margin: 3px 0;
  color: #a8a591;
  position:relative;
  top: 5px;
}

.search-wrapper {
  width: auto;
  height: 60px;
  margin: 0 0 8px;
  padding: 20px 10px 10px 190px;
  border-radius: 0 0 19px 19px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: -15px;
  input::placeholder {
    color: #dfd8d0;
  }
  .search-bar {
    display: flex;
    align-items: center;
    padding-right: 10px;
  }
}

.program-head-name {
  width: 178px;
  height: 24px;
  margin: 14.4px 10px 10.6px 9.5px;
  font-family: FbDakar;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.65px;
  text-align: center;
  color: #e1bb7b;
}

.small-text {
  font-size: 10px;
}

.medium-text {
  font-size: 14px;
}

.large-text {
  font-size: 18px;
}

.xlarge-text {
  font-size: 25px;
}

.xxlarge-text {
  font-size: 50px;
}

.fb-dakar-text {
  font-family: FbDakar;
}

.maccabi-text {
  font-family: 'MaccabiBlock';
}

.maccabi-text-light {
  font-family: 'MaccabiBlock-Light';
}

#root {
  height: 100%;
  top: 100px;
  height: 100vh;
  max-height: 667px;
  width: 100vw;
  max-width: 375px;
}

.App {
  overflow: hidden;
  width: 100vw;
  max-width: 375px;
  background-color: #f6f4e8;
  position: relative;
  height: 667px;
  // min-width: 375px;
  background-size: 25px 25px;
  border-radius: 2px;
  box-shadow: 4px 3px 7px 2px #00000040;
  box-sizing: border-box;
}

.admin-scrollable {
  width: 26px;
  height: 16px;
  margin: 1px 0;
  font-family: MaccabiBlock_V2;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.32px;
  text-align: center;
  color: #a8a591;
}

.search-input-box {
  width: 200px;
  height: 24px;
  margin: 0 6.8px 0.2px 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.21px;
  text-align: right;
  border-style: none;
}

.upload-button {
  min-height: 55px;
  width: 55px;
  position: relative;
  right: 50px;
  bottom: 70px;
  border-radius: 50%;
  border-color: transparent;
  background-color: #79c945;
  color: #fff;
  font-size: 30px;
  padding-bottom: 6px;
  border-width: 0;
  .sign {
    width: 20.1px;
    height: 24.2px;
    object-fit: cover;    
  }
}


.naive-button {
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
}


.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #956b3b;
  border-color: #8d6538;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #c79051;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #c79051;
  border-color: #a87a45;
}


.ant-form-item { 
  margin-bottom: initial;
}

.space-xl {
  margin-bottom: 20px;
}

.space-lg {
  margin-bottom: 10px;
}

.space-md {
  margin-bottom: 5px;
}

.space-sm {
  margin-bottom: 2px;
}

.divider {
  margin: 18px 0;
  border-top: 1px solid #FFE18E;
}
