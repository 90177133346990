.row-box-item-list {
    display: flex;
    cursor: pointer;
    width: auto;
    height: 63px;
    margin: 0 0 5px;
    border-radius: 2px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    align-items: center;
    padding-right: 5px;
}