
.chabad-modal {
    pointer-events: all;
}
.chabad-modal-center {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.chabad-modal-header-title {
    top: 120px;
}
.chabad-modal-header {
    top: 10px;
}

.chabad-modal-close {
    z-index: 1;
    top: -65px;
    margin-left: 155px;
    button {
        pointer-events: all;
    }
}

.chabad-modal-title {
    position: relative;
    top: 75px;
    color: #fbf483;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 70px;
    justify-content: center;
    .modal-headline-subtext {
        font-size: 15px;
    }
    .modal-headline {
        font-family: 'FbDakar';
        font-size: 50px;
        line-height: 0.5;
        display: block;
    }
}

.chabad-modal-body {
    z-index: -1;
    bottom: -160px;
    .chabad-modal-body-content {
        width: 260px;
        position: absolute;
        z-index: 2;
        top: 375px;
        height: 330px;
    }
}


.title-modal.chabad-modal {
    .chabad-modal-center.chabad-modal-header-title {
        top: -14px;
    }

    .chabad-modal-title .modal-headline {
        margin-top: -140px;
    }

    .chabad-modal-body .chabad-modal-body-content {
        top: 260px;
    }
}
